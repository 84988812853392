import React from "react"
import Icon from "./Icon"
import styles from "./ModalStandard.module.css"


class ModalStandard extends React.Component {

  render() {

    return (

      <div className={`${styles.ModalStandard} w-full md:max-w-sm h-full md:h-auto bg-white text-noir shadow-2xl fixed ${this.props.active ? 'block' : 'hidden'}`}>
        <div className={`${styles.ModalCopy} h-full overflow-y-scroll pt-16 p-8 md:p-12 md:pt-16`}>
          {this.props.children}
        </div>
        <button
          aria-label="Close"
          onClick={this.props.handler}
          className={`fixed right-0 bg-highlight py-4 px-6 top-0 z-50 focus:outline-none focus:bg-highlight-lighten10 text-noir`}
        >
          <Icon iconName="icon-times" />
        </button>
      </div>

    )
  }
}

export default ModalStandard