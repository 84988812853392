import React from "react"
import { graphql } from "gatsby"
import statWinner from "../../js/statWinner"
import percentageOf from "../../js/percentageOf"
import uniqueVals from "../../js/uniqueVals"
// import gamesPerStat from "../../js/gamesPerStat"
import Icon from '../../components/Utilities/Icon'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DetailedStatBars from "../../components/DetailedStats/DetailedStatBars"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import CopyBlock from "../../components/Copy/CopyBlock"
import InfoBar from '../../components/Utilities/InfoBar'
import ModalStandard from '../../components/Utilities/ModalStandard'

class PointsContribution extends React.Component {

  state = {
    points: true,
    infoModalActive: false,
  };

  managePoints = () => {
    this.setState(state => ({ points: !state.points }));
  };

  toggleInfoModal = () => {
    this.setState(state => ({ infoModalActive: !state.infoModalActive }));
  };

  render() {

    const pageTitle = "Points Contribution"

    let mdata = this.props.data.allSheetMessiMatchHistory.edges
    let rdata = this.props.data.allSheetRonaldoMatchHistory.edges

    mdata = this.state.points ?
      mdata.filter(d => d.node.competition === "La Liga" || d.node.competition === "Ligue 1" || d.node.competition === "MLS" || d.node.competition === "Serie A" || d.node.competition === "Premier League" || (d.node.competition === "World Cup Qualifier" && d.node.round !== "Play-off") || d.node.round === "Group")
    : mdata

    let mdataClub = mdata.filter(d => d.node.team !== "Argentina")
    let mdataCountry = mdata.filter(d => d.node.team === "Argentina")
    let mdataLeague = mdata.filter(d => d.node.competition === "La Liga" || d.node.competition === "Ligue 1" || d.node.competition === "MLS")
    let mdataSpain = mdata.filter(d => d.node.season === "2009-2010" || d.node.season === "2010-2011" || d.node.season === "2011-2012" || d.node.season === "2012-2013" || d.node.season === "2013-2014" || d.node.season === "2014-2015" || d.node.season === "2015-2016" || d.node.season === "2016-2017" || d.node.season === "2017-2018")

    rdata = this.state.points ?
      rdata.filter(d => d.node.competition === "La Liga" || d.node.competition === "Ligue 1" || d.node.competition === "Serie A" || d.node.competition === "Premier League" || d.node.competition === "Primeira Liga" || d.node.competition === "Saudi Pro League" || (d.node.competition === "World Cup Qualifier" && d.node.round !== "Play-off") || (d.node.competition === "Euros Qualifier" && d.node.round !== "Play-off") || d.node.round === "Group")
    : rdata

    let rdataClub = rdata.filter(d => d.node.team !== "Portugal")
    let rdataCountry = rdata.filter(d => d.node.team === "Portugal")
    let rdataLeague = rdata.filter(d => d.node.competition === "La Liga" || d.node.competition === "Serie A" || d.node.competition === "Premier League" || d.node.competition === "Primeira Liga" || d.node.competition === "Saudi Pro League")
    let rdataSpain = rdata.filter(d => d.node.season === "2009-2010" || d.node.season === "2010-2011" || d.node.season === "2011-2012" || d.node.season === "2012-2013" || d.node.season === "2013-2014" || d.node.season === "2014-2015" || d.node.season === "2015-2016" || d.node.season === "2016-2017" || d.node.season === "2017-2018")


    // Function to work out Contested Points
    const contestedPoints = (data, season) => {
      let pdata = season ? data.filter(d => d.node.season === season) : data
      return pdata.length * 3
    }

    // Function to work out Points Won
    const pointsWon = (data, season) => {
      let pdata = season ? data.filter(d => d.node.season === season) : data
      return (pdata.filter(d => Number(d.node.scoreTeam) === Number(d.node.scoreOpponent)).length)+(pdata.filter(d => Number(d.node.scoreTeam) > Number(d.node.scoreOpponent)).length) * 3
    }

    // Function to work out Points Won Via Goals
    const pointsWonGoals = (data, season) => {
      let pdata = season ? data.filter(d => d.node.season === season) : data
      return pdata.reduce((total, d) => { return (total + Number(d.node.gPointsWon)) }, 0)
    }

    // Function to work out Points Won Via Goals+Assists
    const pointsWonGA = (data, season) => {
      let pdata = season ? data.filter(d => d.node.season === season) : data
      return pdata.reduce((total, d) => { return (total + Number(d.node.gaPointsWon)) }, 0)
    }


    const seasons = uniqueVals(rdata.map(d => d.node.season))
    const seasonsSpain = uniqueVals(rdataSpain.map(d => d.node.season))

    let mSeasonsData = seasons.map(season => ([
      {"node": {
        "year": season,
        "competition": "Club and Country",
        "contestedPoints": contestedPoints(mdata, season),
        "pointsWon": pointsWon(mdata, season),
        "gPointsWon": pointsWonGoals(mdata, season),
        "gaPointsWon": pointsWonGA(mdata, season),
        "subStatPointsWon": percentageOf(pointsWon(mdata, season), contestedPoints(mdata, season)),
        "subStatGPointsWon": percentageOf(pointsWonGoals(mdata, season), pointsWon(mdata, season)),
        "subStatGAPointsWon": percentageOf(pointsWonGA(mdata, season), pointsWon(mdata, season)),
      },}
    ]))
    let rSeasonsData = seasons.map(season => ([
      {"node": {
        "year": season,
        "competition": "Club and Country",
        "contestedPoints": contestedPoints(rdata, season),
        "pointsWon": pointsWon(rdata, season),
        "gPointsWon": pointsWonGoals(rdata, season),
        "gaPointsWon": pointsWonGA(rdata, season),
        "subStatPointsWon": percentageOf(pointsWon(rdata, season), contestedPoints(rdata, season)),
        "subStatGPointsWon": percentageOf(pointsWonGoals(rdata, season), pointsWon(rdata, season)),
        "subStatGAPointsWon": percentageOf(pointsWonGA(rdata, season), pointsWon(rdata, season)),
      },}
    ]))
    mSeasonsData = mSeasonsData.map(d => d[0])
    rSeasonsData = rSeasonsData.map(d => d[0])


    let mSeasonsDataClub = seasons.map(season => ([
      {"node": {
        "year": season,
        "competition": "All Competitions",
        "contestedPoints": contestedPoints(mdataClub, season),
        "pointsWon": pointsWon(mdataClub, season),
        "gPointsWon": pointsWonGoals(mdataClub, season),
        "gaPointsWon": pointsWonGA(mdataClub, season),
        "subStatPointsWon": percentageOf(pointsWon(mdataClub, season), contestedPoints(mdataClub, season)),
        "subStatGPointsWon": percentageOf(pointsWonGoals(mdataClub, season), pointsWon(mdataClub, season)),
        "subStatGAPointsWon": percentageOf(pointsWonGA(mdataClub, season), pointsWon(mdataClub, season)),
      },}
    ]))
    let rSeasonsDataClub = seasons.map(season => ([
      {"node": {
        "year": season,
        "competition": "All Competitions",
        "contestedPoints": contestedPoints(rdataClub, season),
        "pointsWon": pointsWon(rdataClub, season),
        "gPointsWon": pointsWonGoals(rdataClub, season),
        "gaPointsWon": pointsWonGA(rdataClub, season),
        "subStatPointsWon": percentageOf(pointsWon(rdataClub, season), contestedPoints(rdataClub, season)),
        "subStatGPointsWon": percentageOf(pointsWonGoals(rdataClub, season), pointsWon(rdataClub, season)),
        "subStatGAPointsWon": percentageOf(pointsWonGA(rdataClub, season), pointsWon(rdataClub, season)),
      },}
    ]))
    mSeasonsDataClub = mSeasonsDataClub.map(d => d[0])
    rSeasonsDataClub = rSeasonsDataClub.map(d => d[0])


    let mSeasonsDataCountry = seasons.map(season => ([
      {"node": {
        "year": season,
        "competition": "All Time International",
        "contestedPoints": contestedPoints(mdataCountry, season),
        "pointsWon": pointsWon(mdataCountry, season),
        "gPointsWon": pointsWonGoals(mdataCountry, season),
        "gaPointsWon": pointsWonGA(mdataCountry, season),
        "subStatPointsWon": percentageOf(pointsWon(mdataCountry, season), contestedPoints(mdataCountry, season)),
        "subStatGPointsWon": percentageOf(pointsWonGoals(mdataCountry, season), pointsWon(mdataCountry, season)),
        "subStatGAPointsWon": percentageOf(pointsWonGA(mdataCountry, season), pointsWon(mdataCountry, season)),
      },}
    ]))
    let rSeasonsDataCountry = seasons.map(season => ([
      {"node": {
        "year": season,
        "competition": "All Time International",
        "contestedPoints": contestedPoints(rdataCountry, season),
        "pointsWon": pointsWon(rdataCountry, season),
        "gPointsWon": pointsWonGoals(rdataCountry, season),
        "gaPointsWon": pointsWonGA(rdataCountry, season),
        "subStatPointsWon": percentageOf(pointsWon(rdataCountry, season), contestedPoints(rdataCountry, season)),
        "subStatGPointsWon": percentageOf(pointsWonGoals(rdataCountry, season), pointsWon(rdataCountry, season)),
        "subStatGAPointsWon": percentageOf(pointsWonGA(rdataCountry, season), pointsWon(rdataCountry, season)),
      },}
    ]))
    mSeasonsDataCountry = mSeasonsDataCountry.map(d => d[0])
    rSeasonsDataCountry = rSeasonsDataCountry.map(d => d[0])


    let mSeasonsDataLeague = seasons.map(season => ([
      {"node": {
        "year": season,
        "competition": "All Time League",
        "contestedPoints": contestedPoints(mdataLeague, season),
        "pointsWon": pointsWon(mdataLeague, season),
        "gPointsWon": pointsWonGoals(mdataLeague, season),
        "gaPointsWon": pointsWonGA(mdataLeague, season),
        "subStatPointsWon": percentageOf(pointsWon(mdataLeague, season), contestedPoints(mdataLeague, season)),
        "subStatGPointsWon": percentageOf(pointsWonGoals(mdataLeague, season), pointsWon(mdataLeague, season)),
        "subStatGAPointsWon": percentageOf(pointsWonGA(mdataLeague, season), pointsWon(mdataLeague, season)),
      },}
    ]))
    let rSeasonsDataLeague = seasons.map(season => ([
      {"node": {
        "year": season,
        "competition": "All Time League",
        "contestedPoints": contestedPoints(rdataLeague, season),
        "pointsWon": pointsWon(rdataLeague, season),
        "gPointsWon": pointsWonGoals(rdataLeague, season),
        "gaPointsWon": pointsWonGA(rdataLeague, season),
        "subStatPointsWon": percentageOf(pointsWon(rdataLeague, season), contestedPoints(rdataLeague, season)),
        "subStatGPointsWon": percentageOf(pointsWonGoals(rdataLeague, season), pointsWon(rdataLeague, season)),
        "subStatGAPointsWon": percentageOf(pointsWonGA(rdataLeague, season), pointsWon(rdataLeague, season)),
      },}
    ]))
    mSeasonsDataLeague = mSeasonsDataLeague.map(d => d[0])
    rSeasonsDataLeague = rSeasonsDataLeague.map(d => d[0])


    let mSeasonsDataSpain = seasonsSpain.map(season => ([
      {"node": {
        "year": season,
        "competition": "09/10-17/18",
        "contestedPoints": contestedPoints(mdataSpain, season),
        "pointsWon": pointsWon(mdataSpain, season),
        "gPointsWon": pointsWonGoals(mdataSpain, season),
        "gaPointsWon": pointsWonGA(mdataSpain, season),
        "subStatPointsWon": percentageOf(pointsWon(mdataSpain, season), contestedPoints(mdataSpain, season)),
        "subStatGPointsWon": percentageOf(pointsWonGoals(mdataSpain, season), pointsWon(mdataSpain, season)),
        "subStatGAPointsWon": percentageOf(pointsWonGA(mdataSpain, season), pointsWon(mdataSpain, season)),
      },}
    ]))
    let rSeasonsDataSpain = seasonsSpain.map(season => ([
      {"node": {
        "year": season,
        "competition": "09/10-17/18",
        "contestedPoints": contestedPoints(rdataSpain, season),
        "pointsWon": pointsWon(rdataSpain, season),
        "gPointsWon": pointsWonGoals(rdataSpain, season),
        "gaPointsWon": pointsWonGA(rdataSpain, season),
        "subStatPointsWon": percentageOf(pointsWon(rdataSpain, season), contestedPoints(rdataSpain, season)),
        "subStatGPointsWon": percentageOf(pointsWonGoals(rdataSpain, season), pointsWon(rdataSpain, season)),
        "subStatGAPointsWon": percentageOf(pointsWonGA(rdataSpain, season), pointsWon(rdataSpain, season)),
      },}
    ]))
    mSeasonsDataSpain = mSeasonsDataSpain.map(d => d[0])
    rSeasonsDataSpain = rSeasonsDataSpain.map(d => d[0])

    return (

      <Layout>
        <SEO
          title={`Points Won - Messi vs Ronaldo`}
          description={`How meaningful have Messi and Ronaldo's goals and assists been? How many times did they make the difference between losing, drawing or winning?`}
          canonicalPath={`/detailed-stats/points-contribution/`}
        />

        <h1>{pageTitle} <span className="sr-only">- Messi vs Ronaldo</span></h1>

        

        <div className="w-full p-4 max-w-screen-xs mx-auto mt-4 sm:mt-6 md:mt-8 lg:mt-10 xl:mt-16 2xl:mt-20">
          <InfoBar classes="w-full justify-center" msg="Only games where points were at stake are included in these stats (such as league games and group games)." />
        </div>

        <div className="px-4">
          {/* <CopyBlock classes="">
            <div className="flex flex-auto p-1 text-xs w-1/2 md:w-1/5" onChange={this.managePoints}>
              <label className={`flex w-full items-center p-2 cursor-pointer`}>
                <input type="checkbox" checked={this.state.points ? true : false} />
                <span className="ml-2">Apply this logic to all games</span>
              </label>
            </div>
          </CopyBlock> */}

          <button onClick={this.toggleInfoModal} className="mt-8 max-w-md mx-auto text-xs text-center flex justify-center items-center">
            <span className="text-gray-600 px-2 py-1 bg-noir-lighten5 hover:bg-noir-lighten10"><Icon iconName="icon-info-circle" class="text-gray-600 text-xs mr-1" /> Explanation of Points Contribution stats</span>
          </button>
          
          <ModalStandard handler={this.toggleInfoModal} active={this.state.infoModalActive}>
            <h4>Points Won</h4>
            <p>This is simply the number of points their team's have won with them on the pitch. For example, if Juventus win a league game, Ronaldo has won 3 points.</p>

            <h4>Points Won via Goals</h4>
            <p>For this we compare the actual result to what the result would have been if Messi/Ronaldo didn't score.</p>
            <p>For example, if Barcelona won 3-1 and Messi scored once, he won zero points, as his goal didn't affect the result.</p>
            <p>If Barcelona won 2-1 and Messi scored once, he won 2 points, as his goal changed the result from a draw (1 point) to a win (3 points).</p>
            <p>If Barcelona won 2-1 and Messi scored twice, he won 3 points, as his goals changed the result from a loss (0 points) to a win (3 points).</p>

            <h4>Points Won via Goals + Assists</h4>
            <p>This works exactly the same as above, but also factoring in assists.</p>
            <p>For example, if Juventus won 2-1 and Ronaldo scored 1 and assisted 1, he won 3 points, as his goal and assist changed the result from a loss (0 points) to a win (3 points).</p>
          </ModalStandard>
          
        </div>

        <h2 className="text-center uppercase font-semibold mt-12 text-highlight text-xl">All Time Club + Country</h2>

        <div className={`max-w-lg mx-auto flex flex-wrap justify-center pt-2`}>

          <DetailedStatBars
            mdata={mSeasonsData}
            rdata={rSeasonsData}
            mstat={mSeasonsData.reduce((total, d) => { return (total + Number(d.node.pointsWon)) }, 0)}
            rstat={rSeasonsData.reduce((total, d) => { return (total + Number(d.node.pointsWon)) }, 0)}
            competition="All Time Career"
            title="Points Won"
            subtitle="Club and Country"
            playerTitleHidden={`All Time Career Points Won`}
            statType="Points Won"
            btnText="Season Breakdown"
            statView={['Totals','% of Contested Points']}
            mSubStat={`Won ${percentageOf(pointsWon(mdata), contestedPoints(mdata))}% of contested points (${pointsWon(mdata)}/${contestedPoints(mdata)})`}
            rSubStat={`Won ${percentageOf(pointsWon(rdata), contestedPoints(rdata))}% of contested points (${pointsWon(rdata)}/${contestedPoints(rdata)})`}
            subStatW={statWinner(percentageOf(pointsWon(mdata), contestedPoints(mdata)), percentageOf(pointsWon(rdata), contestedPoints(rdata)))}
          />

          <DetailedStatBars
            mdata={mSeasonsData}
            rdata={rSeasonsData}
            mstat={mSeasonsData.reduce((total, d) => { return (total + Number(d.node.gPointsWon)) }, 0)}
            rstat={rSeasonsData.reduce((total, d) => { return (total + Number(d.node.gPointsWon)) }, 0)}
            competition="All Time Career"
            title="Points Won via Goals"
            subtitle="Club and Country"
            playerTitleHidden={`All Time Career Points Won via Goals`}
            statType="Points Won via Goals"
            btnText="Season Breakdown"
            statView={['Totals',"% of Team's Points"]}
            mSubStat={`His goals directly contributed to ${percentageOf(pointsWonGoals(mdata), pointsWon(mdata))}% of points his team won`}
            rSubStat={`His goals directly contributed to ${percentageOf(pointsWonGoals(rdata), pointsWon(rdata))}% of points his team won`}
            subStatW={statWinner(percentageOf(pointsWonGoals(mdata), pointsWon(mdata)), percentageOf(pointsWonGoals(rdata), pointsWon(rdata)))}
          />

          <DetailedStatBars
            mdata={mSeasonsData}
            rdata={rSeasonsData}
            mstat={mSeasonsData.reduce((total, d) => { return (total + Number(d.node.gaPointsWon)) }, 0)}
            rstat={rSeasonsData.reduce((total, d) => { return (total + Number(d.node.gaPointsWon)) }, 0)}
            competition="All Time Career"
            title="Points Won via G+A"
            subtitle="Club and Country"
            playerTitleHidden={`All Time Career Points Won via Goals + Assists`}
            statType="Points Won via Goals + Assists"
            btnText="Season Breakdown"
            statView={['Totals',"% of Team's Points"]}
            mSubStat={`His G+A directly contributed to ${percentageOf(pointsWonGA(mdata), pointsWon(mdata))}% of points his team won)`}
            rSubStat={`His G+A directly contributed to ${percentageOf(pointsWonGA(rdata), pointsWon(rdata))}% of points his team won`}
            subStatW={statWinner(percentageOf(pointsWonGA(mdata), pointsWon(mdata)), percentageOf(pointsWonGA(rdata), pointsWon(rdata)))}
          />

        </div>

        <h2 className="text-center uppercase font-semibold mt-8 text-highlight text-xl">All Time Club</h2>

        <div className={`max-w-lg mx-auto flex flex-wrap justify-center pt-2`}>

          <DetailedStatBars
            mdata={mSeasonsDataClub}
            rdata={rSeasonsDataClub}
            mstat={mSeasonsDataClub.reduce((total, d) => { return (total + Number(d.node.pointsWon)) }, 0)}
            rstat={rSeasonsDataClub.reduce((total, d) => { return (total + Number(d.node.pointsWon)) }, 0)}
            competition="All Time Club"
            title="Points Won"
            subtitle="All Time Club"
            playerTitleHidden={`All Time Club Points Won`}
            statType="Points Won"
            btnText="Season Breakdown"
            statView={['Totals','% of Contested Points']}
            mSubStat={`Won ${percentageOf(pointsWon(mdataClub), contestedPoints(mdataClub))}% of contested points (${pointsWon(mdataClub)}/${contestedPoints(mdataClub)})`}
            rSubStat={`Won ${percentageOf(pointsWon(rdataClub), contestedPoints(rdataClub))}% of contested points (${pointsWon(rdataClub)}/${contestedPoints(rdataClub)})`}
            subStatW={statWinner(percentageOf(pointsWon(mdataClub), contestedPoints(mdataClub)), percentageOf(pointsWon(rdataClub), contestedPoints(rdataClub)))}
          />

          <DetailedStatBars
            mdata={mSeasonsDataClub}
            rdata={rSeasonsDataClub}
            mstat={mSeasonsDataClub.reduce((total, d) => { return (total + Number(d.node.gPointsWon)) }, 0)}
            rstat={rSeasonsDataClub.reduce((total, d) => { return (total + Number(d.node.gPointsWon)) }, 0)}
            competition="All Time Club"
            title="Points Won via Goals"
            subtitle="All Time Club"
            playerTitleHidden={`All Time Club Points Won via Goals`}
            statType="Points Won via Goals"
            btnText="Season Breakdown"
            statView={['Totals',"% of Team's Points"]}
            mSubStat={`His goals directly contributed to ${percentageOf(pointsWonGoals(mdataClub), pointsWon(mdataClub))}% of points his team won`}
            rSubStat={`His goals directly contributed to ${percentageOf(pointsWonGoals(rdataClub), pointsWon(rdataClub))}% of points his team won`}
            subStatW={statWinner(percentageOf(pointsWonGoals(mdataClub), pointsWon(mdataClub)), percentageOf(pointsWonGoals(rdataClub), pointsWon(rdataClub)))}
          />

          <DetailedStatBars
            mdata={mSeasonsDataClub}
            rdata={rSeasonsDataClub}
            mstat={mSeasonsDataClub.reduce((total, d) => { return (total + Number(d.node.gaPointsWon)) }, 0)}
            rstat={rSeasonsDataClub.reduce((total, d) => { return (total + Number(d.node.gaPointsWon)) }, 0)}
            competition="All Time Club"
            title="Points Won via G+A"
            subtitle="All Time Club"
            playerTitleHidden={`All Time Club Points Won via Goals + Assists`}
            statType="Points Won via Goals + Assists"
            btnText="Season Breakdown"
            statView={['Totals',"% of Team's Points"]}
            mSubStat={`His G+A directly contributed to ${percentageOf(pointsWonGA(mdataClub), pointsWon(mdataClub))}% of points his team won)`}
            rSubStat={`His G+A directly contributed to ${percentageOf(pointsWonGA(rdataClub), pointsWon(rdataClub))}% of points his team won`}
            subStatW={statWinner(percentageOf(pointsWonGA(mdataClub), pointsWon(mdataClub)), percentageOf(pointsWonGA(rdataClub), pointsWon(rdataClub)))}
          />

        </div>

        <h2 className="text-center uppercase font-semibold mt-8 text-highlight text-xl">All Time International</h2>

        <div className={`max-w-lg mx-auto flex flex-wrap justify-center pt-2`}>

          <DetailedStatBars
            mdata={mSeasonsDataCountry}
            rdata={rSeasonsDataCountry}
            mstat={mSeasonsDataCountry.reduce((total, d) => { return (total + Number(d.node.pointsWon)) }, 0)}
            rstat={rSeasonsDataCountry.reduce((total, d) => { return (total + Number(d.node.pointsWon)) }, 0)}
            competition="All Time International"
            title="Points Won"
            subtitle="All Time International"
            playerTitleHidden={`All Time International Points Won`}
            statType="Points Won"
            btnText="Season Breakdown"
            statView={['Totals','% of Contested Points']}
            mSubStat={`Won ${percentageOf(pointsWon(mdataCountry), contestedPoints(mdataCountry))}% of contested points (${pointsWon(mdataCountry)}/${contestedPoints(mdataCountry)})`}
            rSubStat={`Won ${percentageOf(pointsWon(rdataCountry), contestedPoints(rdataCountry))}% of contested points (${pointsWon(rdataCountry)}/${contestedPoints(rdataCountry)})`}
            subStatW={statWinner(percentageOf(pointsWon(mdataCountry), contestedPoints(mdataCountry)), percentageOf(pointsWon(rdataCountry), contestedPoints(rdataCountry)))}
          />

          <DetailedStatBars
            mdata={mSeasonsDataCountry}
            rdata={rSeasonsDataCountry}
            mstat={mSeasonsDataCountry.reduce((total, d) => { return (total + Number(d.node.gPointsWon)) }, 0)}
            rstat={rSeasonsDataCountry.reduce((total, d) => { return (total + Number(d.node.gPointsWon)) }, 0)}
            competition="All Time International"
            title="Points Won via Goals"
            subtitle="All Time International"
            playerTitleHidden={`All Time International Points Won via Goals`}
            statType="Points Won via Goals"
            btnText="Season Breakdown"
            statView={['Totals',"% of Team's Points"]}
            mSubStat={`His goals directly contributed to ${percentageOf(pointsWonGoals(mdataCountry), pointsWon(mdataCountry))}% of points his team won`}
            rSubStat={`His goals directly contributed to ${percentageOf(pointsWonGoals(rdataCountry), pointsWon(rdataCountry))}% of points his team won`}
            subStatW={statWinner(percentageOf(pointsWonGoals(mdataCountry), pointsWon(mdataCountry)), percentageOf(pointsWonGoals(rdataCountry), pointsWon(rdataCountry)))}
          />

          <DetailedStatBars
            mdata={mSeasonsDataCountry}
            rdata={rSeasonsDataCountry}
            mstat={mSeasonsDataCountry.reduce((total, d) => { return (total + Number(d.node.gaPointsWon)) }, 0)}
            rstat={rSeasonsDataCountry.reduce((total, d) => { return (total + Number(d.node.gaPointsWon)) }, 0)}
            competition="All Time International"
            title="Points Won via G+A"
            subtitle="All Time International"
            playerTitleHidden={`All Time International Points Won via Goals + Assists`}
            statType="Points Won via Goals + Assists"
            btnText="Season Breakdown"
            statView={['Totals',"% of Team's Points"]}
            mSubStat={`His G+A directly contributed to ${percentageOf(pointsWonGA(mdataCountry), pointsWon(mdataCountry))}% of points his team won)`}
            rSubStat={`His G+A directly contributed to ${percentageOf(pointsWonGA(rdataCountry), pointsWon(rdataCountry))}% of points his team won`}
            subStatW={statWinner(percentageOf(pointsWonGA(mdataCountry), pointsWon(mdataCountry)), percentageOf(pointsWonGA(rdataCountry), pointsWon(rdataCountry)))}
          />

        </div>

        <h2 className="text-center uppercase font-semibold mt-8 text-highlight text-xl">All Time Domestic Leagues</h2>

        <div className={`max-w-lg mx-auto flex flex-wrap justify-center pt-2`}>

          <DetailedStatBars
            mdata={mSeasonsDataLeague}
            rdata={rSeasonsDataLeague}
            mstat={mSeasonsDataLeague.reduce((total, d) => { return (total + Number(d.node.pointsWon)) }, 0)}
            rstat={rSeasonsDataLeague.reduce((total, d) => { return (total + Number(d.node.pointsWon)) }, 0)}
            competition="All Time League"
            title="Points Won"
            subtitle="All Time League"
            playerTitleHidden={`All Time League Points Won`}
            statType="Points Won"
            btnText="Season Breakdown"
            statView={['Totals','% of Contested Points']}
            mSubStat={`Won ${percentageOf(pointsWon(mdataLeague), contestedPoints(mdataLeague))}% of contested points (${pointsWon(mdataLeague)}/${contestedPoints(mdataLeague)})`}
            rSubStat={`Won ${percentageOf(pointsWon(rdataLeague), contestedPoints(rdataLeague))}% of contested points (${pointsWon(rdataLeague)}/${contestedPoints(rdataLeague)})`}
            subStatW={statWinner(percentageOf(pointsWon(mdataLeague), contestedPoints(mdataLeague)), percentageOf(pointsWon(rdataLeague), contestedPoints(rdataLeague)))}
          />

          <DetailedStatBars
            mdata={mSeasonsDataLeague}
            rdata={rSeasonsDataLeague}
            mstat={mSeasonsDataLeague.reduce((total, d) => { return (total + Number(d.node.gPointsWon)) }, 0)}
            rstat={rSeasonsDataLeague.reduce((total, d) => { return (total + Number(d.node.gPointsWon)) }, 0)}
            competition="All Time League"
            title="Points Won via Goals"
            subtitle="All Time League"
            playerTitleHidden={`All Time League Points Won via Goals`}
            statType="Points Won via Goals"
            btnText="Season Breakdown"
            statView={['Totals',"% of Team's Points"]}
            mSubStat={`His goals directly contributed to ${percentageOf(pointsWonGoals(mdataLeague), pointsWon(mdataLeague))}% of points his team won`}
            rSubStat={`His goals directly contributed to ${percentageOf(pointsWonGoals(rdataLeague), pointsWon(rdataLeague))}% of points his team won`}
            subStatW={statWinner(percentageOf(pointsWonGoals(mdataLeague), pointsWon(mdataLeague)), percentageOf(pointsWonGoals(rdataLeague), pointsWon(rdataLeague)))}
          />

          <DetailedStatBars
            mdata={mSeasonsDataLeague}
            rdata={rSeasonsDataLeague}
            mstat={mSeasonsDataLeague.reduce((total, d) => { return (total + Number(d.node.gaPointsWon)) }, 0)}
            rstat={rSeasonsDataLeague.reduce((total, d) => { return (total + Number(d.node.gaPointsWon)) }, 0)}
            competition="All Time League"
            title="Points Won via G+A"
            subtitle="All Time League"
            playerTitleHidden={`All Time League Points Won via Goals + Assists`}
            statType="Points Won via Goals + Assists"
            btnText="Season Breakdown"
            statView={['Totals',"% of Team's Points"]}
            mSubStat={`His G+A directly contributed to ${percentageOf(pointsWonGA(mdataLeague), pointsWon(mdataLeague))}% of points his team won)`}
            rSubStat={`His G+A directly contributed to ${percentageOf(pointsWonGA(rdataLeague), pointsWon(rdataLeague))}% of points his team won`}
            subStatW={statWinner(percentageOf(pointsWonGA(mdataLeague), pointsWon(mdataLeague)), percentageOf(pointsWonGA(rdataLeague), pointsWon(rdataLeague)))}
          />

        </div>


        <h2 className="text-center uppercase font-semibold mt-8 text-highlight text-xl">When both played in Spain (Club + Country)</h2>

        <div className={`max-w-lg mx-auto flex flex-wrap justify-center pt-2`}>

        <DetailedStatBars
            mdata={mSeasonsDataSpain}
            rdata={rSeasonsDataSpain}
            mstat={mSeasonsDataSpain.reduce((total, d) => { return (total + Number(d.node.pointsWon)) }, 0)}
            rstat={rSeasonsDataSpain.reduce((total, d) => { return (total + Number(d.node.pointsWon)) }, 0)}
            competition="09/10-17/18"
            title="Points Won"
            subtitle="09/10-17/18"
            playerTitleHidden={`09/10-17/18 Points Won`}
            statType="Points Won"
            btnText="Season Breakdown"
            statView={['Totals','% of Contested Points']}
            mSubStat={`Won ${percentageOf(pointsWon(mdataSpain), contestedPoints(mdataSpain))}% of contested points (${pointsWon(mdataSpain)}/${contestedPoints(mdataSpain)})`}
            rSubStat={`Won ${percentageOf(pointsWon(rdataSpain), contestedPoints(rdataSpain))}% of contested points (${pointsWon(rdataSpain)}/${contestedPoints(rdataSpain)})`}
            subStatW={statWinner(percentageOf(pointsWon(mdataSpain), contestedPoints(mdataSpain)), percentageOf(pointsWon(rdataSpain), contestedPoints(rdataSpain)))}
          />

          <DetailedStatBars
            mdata={mSeasonsDataSpain}
            rdata={rSeasonsDataSpain}
            mstat={mSeasonsDataSpain.reduce((total, d) => { return (total + Number(d.node.gPointsWon)) }, 0)}
            rstat={rSeasonsDataSpain.reduce((total, d) => { return (total + Number(d.node.gPointsWon)) }, 0)}
            competition="09/10-17/18"
            title="Points Won via Goals"
            subtitle="09/10-17/18"
            playerTitleHidden={`09/10-17/18 Points Won via Goals`}
            statType="Points Won via Goals"
            btnText="Season Breakdown"
            statView={['Totals',"% of Team's Points"]}
            mSubStat={`His goals directly contributed to ${percentageOf(pointsWonGoals(mdataSpain), pointsWon(mdataSpain))}% of points his team won`}
            rSubStat={`His goals directly contributed to ${percentageOf(pointsWonGoals(rdataSpain), pointsWon(rdataSpain))}% of points his team won`}
            subStatW={statWinner(percentageOf(pointsWonGoals(mdataSpain), pointsWon(mdataSpain)), percentageOf(pointsWonGoals(rdataSpain), pointsWon(rdataSpain)))}
          />

          <DetailedStatBars
            mdata={mSeasonsDataSpain}
            rdata={rSeasonsDataSpain}
            mstat={mSeasonsDataSpain.reduce((total, d) => { return (total + Number(d.node.gaPointsWon)) }, 0)}
            rstat={rSeasonsDataSpain.reduce((total, d) => { return (total + Number(d.node.gaPointsWon)) }, 0)}
            competition="09/10-17/18"
            title="Points Won via G+A"
            subtitle="09/10-17/18"
            playerTitleHidden={`09/10-17/18 Points Won via Goals + Assists`}
            statType="Points Won via Goals + Assists"
            btnText="Season Breakdown"
            statView={['Totals',"% of Team's Points"]}
            mSubStat={`His G+A directly contributed to ${percentageOf(pointsWonGA(mdataSpain), pointsWon(mdataSpain))}% of points his team won)`}
            rSubStat={`His G+A directly contributed to ${percentageOf(pointsWonGA(rdataSpain), pointsWon(rdataSpain))}% of points his team won`}
            subStatW={statWinner(percentageOf(pointsWonGA(mdataSpain), pointsWon(mdataSpain)), percentageOf(pointsWonGA(rdataSpain), pointsWon(rdataSpain)))}
          />

        </div>

        <CopyBlock>
          <h2>Points Contribution</h2>

          <h3>Points Won</h3>
          <p>Overall, when Lionel Messi has been on the pitch his teams have <strong className="text-messi">won {percentageOf(pointsWon(mdata), contestedPoints(mdata))}% of available points</strong> ({pointsWon(mdata)} from a possible {contestedPoints(mdata)}), whereas for Cristiano Ronaldo, his teams have <strong className="text-ronaldo">won {percentageOf(pointsWon(rdata), contestedPoints(rdata))}% of available points</strong> ({pointsWon(rdata)} from a possible {contestedPoints(rdata)}) with him on the pitch.</p>

          <h3>Points Won via Goals</h3>
          <p>From those {pointsWon(mdata)} points that Messi's team won with him on the pitch, <strong className="text-messi">{percentageOf(pointsWonGoals(mdata), pointsWon(mdata))}%</strong> ({pointsWonGoals(mdata)}) of them were won because of goals scored by Leo Messi.</p>

          <p>From the {pointsWon(rdata)} points that Ronaldo's team won with him on the pitch, <strong className="text-ronaldo">{percentageOf(pointsWonGoals(rdata), pointsWon(rdata))}%</strong> ({pointsWonGoals(rdata)}) of them were won because of goals scored by Cristiano.</p>

          <h3>Points Won via Goals and Assists</h3>
          <p>From the {pointsWon(mdata)} points that Messi's team won with him on the pitch, <strong className="text-messi">{percentageOf(pointsWonGA(mdata), pointsWon(mdata))}%</strong> ({pointsWonGA(mdata)}) of them were won because of goals scored or assisted by Leo Messi.</p>

          <p>From the {pointsWon(rdata)} points that Ronaldo's team won with him on the pitch, <strong className="text-ronaldo">{percentageOf(pointsWonGA(rdata), pointsWon(rdata))}%</strong> ({pointsWonGA(rdata)}) of them were won because of goals scored or assisted by Cristiano.</p>

          <h3>What conclusions can we draw from the above stats?</h3>

          <p>Overall, <strong className="text-highlight">Messi's teams have won a higher percentage of points than Ronaldo's, but not by a huge margin</strong>. In the case of this particular stat, you could argue that it's because Messi's team was superior, or you could argue that Messi's presence in his team was more effective than Ronaldo's.</p>

          <p>In terms of <strong className="text-highlight">points won directly from goals scored</strong>, Ronaldo now has a slight edge overall but the difference is extremely minimal, to the extent that we can't really draw any significant conclusions. <strong className="text-highlight">Both players' goals have had about the same impact in terms of their teams' results.</strong></p>

          <p>When it comes to <strong className="text-highlight">points won from goals <em>and</em> assists</strong>, we can see that <strong className="text-highlight">Messi's goal contributions win points for his team more often than Ronaldo's goal contributions</strong>. However, rather than Messi's assists being more meaningful, this can probably be attributed to the fact that Leo Messi registers considerably more assists in general than Cristiano.</p>

          <p></p>
        </CopyBlock>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetMessiMatchHistory {
      edges {
        node {
          date
          year
          season
          team
          scoreTeam
          scoreOpponent
          competition
          round
          gPointsWon
          gaPointsWon
        }
      }
    }
    allSheetRonaldoMatchHistory {
      edges {
        node {
          date
          year
          season
          team
          scoreTeam
          scoreOpponent
          competition
          round
          gPointsWon
          gaPointsWon
        }
      }
    }
  }
`

export default PointsContribution
